import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class PropertyCard extends React.Component {
  render() {
    const post = this.props.post

    return (
      <div className="is-parent column is-4">
        <Link to={post.fields.slug}>
          <article
            className={`card ${
              post.frontmatter.featured ? 'is-featured' : ''
            }`}
          >
            {post.frontmatter.cardImage ? (
              <div className="card-image">
                <figure className="image">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.cardImage,
                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                  }}
                />
                </figure>
              </div>
            ) : null}
            <div className="card-content">
              <h6 className="card-content-title title is-size-5 is-size-4-desktop">
                {post.frontmatter.title}
              </h6>
              <p className="subtitle is-size-6 is-size-5-desktop is-block">
                <span
                  className="icon-text"
                  style={{
                    marginLeft: '-3px'
                  }}
                >
                  <span className="icon">
                    <i className="icon-map-marker-alt"></i>
                  </span>
                  <span>{post.frontmatter.location.province}</span>
                </span>
              </p>
              <p className="is-size-5 has-text-info has-text-weight-semibold">
                {post.frontmatter.price.selling}
              </p>

              <div className="card-tags card-tags-top is-flex">
                {post.frontmatter.price.selling ? (
                <span className="card-tag tag is-light">
                  ขาย
                </span>
                ) : null}

                {post.frontmatter.price.rental ? (
                <span className="card-tag tag is-dark">
                  เช่า {post.frontmatter.price.rental}
                </span>
                ) : null}

                {post.frontmatter.freeTransfer && (
                  <span className="card-tag tag is-info ml-auto mr-0">
                    ฟรีโอน
                  </span>
                )}
              </div>

              <div className="card-tags card-tags-bottom">
                <span className="card-tag tag is-light">
                  <span className="icon-text">
                    <span className="icon">
                      <i className="icon-drafting-compass"></i>
                    </span>
                    <span>{post.frontmatter.size}</span>
                  </span>
                </span>
              </div>

            </div>
          </article>
        </Link>
      </div>
    )
  }
}

PropertyCard.propTypes = {
  post: PropTypes.object
}

export default PropertyCard
