import React from 'react'
import PropTypes from 'prop-types'
import PropertyCard from './PropertyCard'

class PropertyGrid extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="has-padding-left-10-mobile has-padding-right-10-mobile">
        <div className="columns is-multiline">
          {posts &&
            posts.map(({ node: post }) => (
              <PropertyCard
                post={post}
                key={post.id}
              />
            ))}
        </div>
      </div>
    )
  }
}

PropertyGrid.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default PropertyGrid
